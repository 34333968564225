import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
import Note from "../../components/text-decorations/note";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "account-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#account-commands",
        "aria-label": "account commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Account commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`docker login`}</inlineCode>{` - Register or log in to a Docker registry`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker logout`}</inlineCode>{` - Log out from a Docker registry`}</li>
    </ul>
    <h2 {...{
      "id": "basic-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basic-commands",
        "aria-label": "basic commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basic Commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`docker pull`}</inlineCode>{` - Pull image or repository from a Docker registry`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker push`}</inlineCode>{` - Push an image or repository to a Docker registry`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker tag [IMAGE] [NAME:TAG]`}</inlineCode>{` - Tag a docker image with name and chosen tag`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker build [PATH | URL]`}</inlineCode>{` - Build an image from a Dockerfile located in PATH or URL`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker images`}</inlineCode>{` - List all Docker images`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker ps`}</inlineCode>{` - List all Docker containers`}</li>
    </ul>
    <h3 {...{
      "id": "tagging-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tagging-example",
        "aria-label": "tagging example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tagging example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`docker tag <image hash> <dockerhub repo/name>:latest
docker push <dockerhub repo/name>:latest
`}</code></pre>
    <h2 {...{
      "id": "container-commands",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#container-commands",
        "aria-label": "container commands permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Container Commands`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`docker cp [LOCAL PATH] [CONTAINER:DEST_PATH`}</inlineCode>{` - Copy files from host to Docker container`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker cp [CONTAINER:PATH] [LOCAL PATH]`}</inlineCode>{` -  Copy files from Docker container to local path`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker attach CONTAINER`}</inlineCode>{` - Attach local standard input, ouput and error streams to a running Docker container`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker run`}</inlineCode>{` - Run a command in a new Docker container`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker start`}</inlineCode>{` - Start one or more stopped Docker containers`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker stop`}</inlineCode>{` - Stop a running Docker container`}</li>
    </ul>
    <h2 {...{
      "id": "cleaning-up",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cleaning-up",
        "aria-label": "cleaning up permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Cleaning Up`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`docker rmi`}</inlineCode>{` - Remove one or more images`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker rm`}</inlineCode>{` - Remove one or more containers`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker system prune`}</inlineCode>{` - Remove unused data`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`docker system prune -a`}</inlineCode>{` - Remove all unused images`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      